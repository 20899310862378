import React, { useState } from 'react';
import '../../styles/style.scss';

import Layout from '../../components/layout';

export default () => {
  const [currentTab, setCurrentTab] = useState('INDIVIDUAL');
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    currentTab,
    setCurrentTab,
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} id='privacy-policy'>
      <div className='privacy-policy-container'>
        <h1>PRIVACY POLICY FOR APOLLO INSURANCE</h1>
        Protecting your private information is our priority. This Privacy Policy governs data collection and usage of the products, services, software, platform
        and websites (collectively, “Services”) provided by Apollo Insurance Group, Inc and any of our affiliates (collectively, Apollo Insurance Group, Inc”).
        By using the Services, you consent to the data practices described in this policy. If you do not agree to any part of this Privacy Policy, then you
        should stop accessing the Services.
        <h2>1. COLLECTION OF YOUR PERSONAL INFORMATION</h2>
        Apollo Insurance Group, Inc may collect personally identifiable information, such as your e-mail address, name, physical address or telephone number.
        Apollo Insurance Group, Inc may also collect anonymous demographic information, which is not unique to you, such as your Postal Code, age, gender,
        preferences, interests and favorites. Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data
        through the Services, this information may be collected and used by others. Apollo Insurance Group, Inc encourages you to review the privacy statements
        of websites you choose to link to from our website so that you can understand how those websites collect, use and share your information. Apollo
        Insurance Group, Inc is not responsible for the privacy statements or other content on websites outside of the websites we operate.
        <h2>2. USE OF YOUR PERSONAL INFORMATION</h2>
        Apollo Insurance Group, Inc does not sell, rent or lease its customer lists to third parties. Apollo Insurance Group, Inc may share data with trusted
        partners to help us perform statistical analysis, send you email or postal mail or provide customer support. Apollo Insurance Group, Inc keeps track of
        the pages our customers visit within our websites in order to determine what Services are the most popular. This data is used to deliver customized
        content and advertising within the websites we operate to users whose behavior indicates that they are interested in a particular subject area. Apollo
        Insurance Group, Inc uses tracking pixels to collect information that helps us provide our Service to you. Please note that any “do not track” signals
        sent by your web browser or other mechanism have no effect on the collection of personally identifiable information by Apollo Insurance Group, Inc. In
        addition, other parties who perform data analytics for Apollo Insurance Group, Inc, such as Google Analytics, may collect personally identifiable
        information about your online activities when you use our Services. Third party affiliates of Apollo Insurance Group, Inc may have different policies
        with respect to “do not track” signals and Apollo Insurance Group, Inc makes no representations with respect to such policies. Apollo Insurance Group,
        Inc uses tracking to create remarketing audiences of users based on user behavior/their visits to our website. Apollo Insurance Group, Inc may also
        target these users with custom ads based on affinities/interests, geographic and demographic signals. Apollo Insurance Group, Inc only shares
        browser-level data through third party vendor tracking such as Google Analytics. Users can opt out of these advertising features by visiting Google or
        resources like the NAI consumer opt-out page. Apollo Insurance Group, Inc may use email addresses to target users with custom ads through 3rd party
        advertising platforms. Apollo Insurance Group, Inc shares customer information with third parties to perform services (advertising) on our behalf.
        Apollo Insurance Group Inc. may disclose personal information when we are required to or we believe it is appropriate to comply with the law (e.g., a
        lawful subpoena, warrant or court order); to enforce or apply this Privacy Policy or our other policies or agreements; to initiate, render, bill, and
        collect for amounts owed to us; to protect our or our users’ rights, property or safety; to protect our users from fraudulent, abusive, or unlawful use
        of the Services; or if we believe that an emergency involving the danger of death or serious physical injury to any person requires or justifies
        disclosure of personal information. Information about our customers, including personal information, may be disclosed as part of any merger,
        acquisition, debt financing, sale of company assets, as well as in the event of an insolvency, bankruptcy or receivership in which personal information
        could be transferred to third parties as one of Apollo Insurance Group, Inc business assets. In such an event, we will attempt to notify you before your
        personal information is transferred, but you may not have the right to opt out of any such transfer.
        <h2>3. USE OF COOKIES</h2>
        Our website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page
        server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web
        server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The
        purpose of a cookie is to tell the Web server that you have returned to a specific page. You have the ability to accept or decline cookies. Most Web
        browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline
        cookies, you may not be able to fully experience the interactive features of the Services.
        <h2>4. SECURITY OF YOUR PERSONAL INFORMATION</h2>
        Apollo Insurance Group, Inc secures your personal information from unauthorized access, use or disclosure. In addition, Apollo Insurance Group, Inc
        secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access,
        use or disclosure.
        <h2>5. CHILDREN UNDER THIRTEEN</h2>
        Our Services are not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children
        under 13. If you become aware that a child has provided us with Personal Information, please contact us as set forth in this Privacy Policy.
        <h2>6. CHANGES TO THIS PRIVACY POLICY</h2>
        Apollo Insurance Group, Inc may change the provisions of this Privacy Policy at any time, and will always post the most up-to-date version on our
        website.
        <h2>7. CONTACT US</h2>
        To contact Apollo Insurance Group, Inc concerning information contained within this Privacy Policy, please call 913-279-0077.
      </div>
    </Layout>
  );
};
